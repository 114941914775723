import React, {useMemo, useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ChevronDown, Minus, Plus, X} from 'react-feather';
import {Capacitor} from '@capacitor/core';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {addComma} from '@/utils';
import {SoldOutModalInfo, isOpenSoldOutModal} from '@/store';
import {user, UserInfo} from '@/store/user';
import {getContentsDetail} from '@/services/magazine';
import useLogger, {Action} from '@/hooks/useLogger';
import useAuth from '@/hooks/useAuth';

import styles from './styles.module.scss';
import InstallAlert from '../../installAlert';
import OrderSoldOutModal from './ConfirmModal';
import Options from './Options';
import AuthModal from '../AuthModal';

interface Props {
  select: (sc: BrandInfo) => void;
  isOpen: boolean;
  togglePopup: (is: boolean) => void;
  options: PopupOptions[] | undefined;
  magazine: MagazineItem;
  magazineId: number;
  buyBenefit?: (selectedOptionId: number, options?: string[]) => void;
  buyBtnTxt?: string;
}

export interface ProductOptions {
  key: string;
  value: string;
  id: number;
  isSoldout: boolean;
  amount?: number;
}

export default function ProductOptionSelector({
  isOpen,
  options,
  magazine,
  magazineId,
  togglePopup,
  buyBenefit,
  buyBtnTxt,
}: Props) {
  const navigator = useNavigate();
  const {openAuth} = useAuth();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedDate, setSelectedDate] = useState<{
    key: string;
    value: string;
  }>();
  const [selectedTime, setSelectedTime] = useState<{
    key: string;
    value: string;
  }>();
  const [isOpenOption1, setIsOpenOption1] = useState(true);
  const [isOpenOption2, setIsOpenOption2] = useState(false);
  const [option1PH] = useState('옵션 1');
  const [option2PH] = useState('옵션 2');
  const [isWeb, setIsWeb] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [hasCountError, setHasCountError] = useState(false);
  const [count, setCount] = useState(1);
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
  const userInfo = useRecoilValue(UserInfo);
  const setSoldoutType = useSetRecoilState(SoldOutModalInfo);
  const setSoldoutModal = useSetRecoilState(isOpenSoldOutModal);
  const accessToken = useRecoilValue(user);
  const sender = useLogger();
  const maxCount = useMemo(() => {
    if (options && selectedDate && selectedTime) {
      const _option1Obj = options.find(option => {
        return option.value === selectedDate.value;
      });
      const _lastOptionObj = _option1Obj?.child?.find(
        c => c.value === selectedTime.value,
      );
      const {limitPerUser} = _lastOptionObj as PopupOptions;
      return limitPerUser;
    }
  }, [options, selectedDate, selectedTime]);
  const limitPerUser = useMemo(() => {
    let _count = 0;
    if (options && selectedDate && selectedTime) {
      const _option1Obj = options.find(option => {
        return option.value === selectedDate.value;
      });
      const _lastOptionObj = _option1Obj?.child?.find(
        c => c.value === selectedTime.value,
      );
      const {limit, soldCount, limitPerUser} = _lastOptionObj as PopupOptions;
      _count =
        limit - soldCount < limitPerUser ? limit - soldCount : limitPerUser;
      return _count;
    }
    if (options && selectedDate) {
      const _option1Obj = options.find(option => {
        return option.value === selectedDate.value;
      });

      if (_option1Obj?.child)
        for (let i = 0; i < _option1Obj?.child.length; i++) {
          const value = _option1Obj?.child[i];

          const {limit, soldCount, limitPerUser} = value as PopupOptions;

          const avail =
            limitPerUser < limit - soldCount ? limitPerUser : limit - soldCount;
          if (_count < avail) _count = avail;
        }
    }

    return _count;
  }, [options, selectedDate, selectedTime]);
  const option1 = useMemo(() => {
    let list: ProductOptions[] = [];
    if (options) {
      list = options.map(option => {
        return {
          key: option.value,
          value: option.value,
          id: option.id,
          isSoldout: option.limit - option.soldCount === 0,
        };
      });
      //setOption1PH(options[0].type);
      //options[0].child ? setOption2PH(options[0].child[0].type) : null;
    }
    return list;
  }, [options]);
  const option2 = useMemo(() => {
    let list: ProductOptions[] = [];
    if (options && selectedDate) {
      const _option1Obj = options.find(option => {
        return option.value === selectedDate.value;
      });
      list = _option1Obj?.child
        ? _option1Obj?.child.map(c => {
            return {
              key: `${c.value}`,
              value: c.value,
              id: c.id,
              isSoldout: c.limit - c.soldCount === 0,
            };
          })
        : [];
    }
    return list;
  }, [options, selectedDate]);
  const price = useMemo(() => {
    let _price = 0;
    if (options && selectedDate && selectedTime) {
      const parentObj = options.find(option => {
        return option.value === selectedDate.value;
      });
      const childObj = parentObj?.child.find(c => {
        return c.value === selectedTime.value;
      });
      _price = childObj?.amount || 0;
    }
    return _price;
  }, [selectedTime]);
  const isDisabledButton = useMemo(() => {
    if (selectedDate && selectedTime) {
      return false;
    }
    return true;
  }, [selectedDate, selectedTime]);
  const getOption = (
    id: number,
    options: PopupOptions[],
  ): PopupOptions | null => {
    let option = null;
    for (let i = 0, max = options.length; i < max; i++) {
      const opt = options[i];
      if (opt.id === id) {
        option = opt;
        break;
      } else {
        option = opt.child ? getOption(id, opt.child) : null;
      }
    }
    return option;
  };

  const clickOption1EmptyNotice = (item: ProductOptions) => {
    const id = magazineId;
    const detailInfo = magazine;
    const _option1 = item;
    navigator(`/order/emptyoptions/${id}`, {
      state: {
        detailInfo,
        prevPath: `${location.pathname}${location.search}`,
        option1: _option1,
      },
    });
  };

  const clickEmptyNotice = (item: ProductOptions) => {
    const id = magazineId;
    const detailInfo = magazine;
    const _option1 = option1.find(o => o.key === selectedDate?.key);
    const _option2 = item;
    navigator(`/order/emptyoptions/${id}`, {
      state: {
        detailInfo,
        prevPath: `${location.pathname}${location.search}`,
        option1: _option1,
        option2: _option2,
      },
    });
  };

  const moveToOrder = () => {
    /*if (magazine.isBought) {
      setSoldoutType('popup_max');
      setSoldoutModal(true);
      return;
    }*/

    sender({
      _msg: 'buy_click2',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });

    getContentsDetail(accessToken, magazine.id).then((data: MagazineItem) => {
      const selectedOption = getOption(
        selectedOptionId as number,
        data.popupInfo?.options as PopupOptions[],
      );
      if (selectedOption) {
        const isSoldout =
          (selectedOption as PopupOptions).limit -
            (selectedOption as PopupOptions).soldCount ===
          0;
        if (isSoldout) {
          setSoldoutType('option');
          setSoldoutModal(true);
          return;
        }
      }

      const ageLimit = magazine.popupInfo?.ageLimit;
      const isIdentified = userInfo.isIdentified;
      if (ageLimit && !isIdentified) {
        openAuth(`/order/detail/${magazineId}`, {
          magazine,
          selectedDate,
          selectedTime,
          amount: count,
          price,
          option: selectedOptionId,
        });
        return;
      }

      if (!userInfo.isAdult && ageLimit) {
        return setIsOpenAuthModal(true);
      }
      navigator(`/order/detail/${magazineId}`, {
        state: {
          magazine,
          selectedDate,
          selectedTime,
          amount: count,
          price,
          option: selectedOptionId,
        },
      });
    });
  };
  const toggleItem = (type: string, item: ProductOptions) => {
    if (type === 'date') {
      setSelectedDate(item);
      setIsOpenOption2(true);
    }
    if (type === 'time') {
      setSelectedTime(item);
      setSelectedOptionId(item.id);
      setIsOpenOption1(false);
    }
  };
  const clearOption = () => {
    setSelectedDate(undefined);
    setSelectedTime(undefined);
    setHasCountError(false);
    setCount(1);
  };
  const updateCount = (type: 'plus' | 'minus') => {
    switch (type) {
      case 'plus':
        if (limitPerUser > count) {
          setCount(count + 1);
        }
        if (maxCount !== limitPerUser) {
          if (count + 1 >= limitPerUser) {
            setHasCountError(true);
          }
        }
        break;
      case 'minus':
        setCount(count === 1 ? count : count - 1);
        setHasCountError(false);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target) && !isWeb) {
      togglePopup(false);
    }
  };

  const openOption1 = (is: boolean, v: any) => {
    setIsOpenOption1(is);
    if (v) {
      setIsOpenOption2(!is);
    }
  };

  const openOption2 = (is: boolean) => {
    setIsOpenOption2(is);
  };

  const buyAction = () => {
    buyBenefit
      ? buyBenefit(selectedOptionId as number, [
          selectedDate?.value || '',
          selectedTime?.value || '',
        ])
      : moveToOrder();
  };

  return (
    <div
      className={`modal-wrapper`}
      style={{display: isOpen ? 'flex' : 'none'}}>
      <div
        className={`modal-contents category ${isOpen ? 'active' : ''}  ${
          styles.option_selector_wrapper
        }`}
        ref={wrapperRef}
        style={{padding: '5px 20px 13px 20px'}}>
        <div className={`filter-modal-header`}>
          <ChevronDown
            size={'30px'}
            color={'#D2D0D5'}
            style={{cursor: 'pointer'}}
            onClick={() => togglePopup(false)}
          />
        </div>
        <div className="brand-modal-body" style={{paddingTop: '0px'}}>
          <Options
            type={'date'}
            toggle={toggleItem}
            placeholder={option1PH}
            optionList={option1}
            disabled={!!selectedDate && !!selectedTime}
            selectedValue={selectedDate?.key || ''}
            isOpenList={isOpenOption1}
            setIsOpenList={openOption1}
            onClickEmptyNotice={clickOption1EmptyNotice}
          />
          <Options
            type={'time'}
            toggle={toggleItem}
            placeholder={option2PH}
            optionList={option2}
            disabled={!selectedDate || (!!selectedDate && !!selectedTime)}
            selectedValue={selectedTime?.key || ''}
            isOpenList={isOpenOption2}
            setIsOpenList={openOption2}
            onClickEmptyNotice={clickEmptyNotice}
          />
          <div
            className={styles.buy_info_panel}
            style={{
              display: !!selectedDate && !!selectedTime ? 'block' : 'none',
            }}>
            <span className={styles.close}>
              <X
                size={'16px'}
                color={'#9F9CA3'}
                onClick={() => clearOption()}
              />
            </span>
            <div className={styles.option}>{selectedDate?.key}</div>
            <div className={styles.option}>{selectedTime?.key}</div>
            <div className={styles.price_wrapper}>
              <span className={styles.price}>{`${addComma(
                price * count,
              )}원`}</span>
              <span className={styles.counter}>
                <Minus
                  size={'16px'}
                  color={count === 1 ? '#D2D0D5' : '#000'}
                  style={count != 1 ? {cursor: 'pointer'} : {}}
                  onClick={() => updateCount('minus')}
                />
                <div className={styles.count}>{count}</div>
                <Plus
                  size={'16px'}
                  color={count === maxCount ? '#D2D0D5' : '#000'}
                  style={count != maxCount ? {cursor: 'pointer'} : {}}
                  onClick={() => {
                    updateCount('plus');
                  }}
                />
              </span>
            </div>
          </div>
          {
            <div
              className={`${
                hasCountError ? styles.error_count : styles.warning
              }`}>
              {!hasCountError &&
                selectedDate &&
                maxCount &&
                `* 1인 기준 최대 ${maxCount}매까지 구매 가능합니다.`}
              {hasCountError &&
                selectedDate &&
                `* 현재 남은 티켓이 소진되어 수량 추가가 불가합니다.`}
            </div>
          }
        </div>
        <div className={`filter-modal-footer ${styles.modal_footer}`}>
          <button
            className={`modal btn black-filled ${
              isDisabledButton ? styles.disable_button : ''
            }`}
            style={{
              marginBottom: Capacitor.getPlatform() === 'ios' ? '10px' : '0',
            }}
            disabled={isDisabledButton}
            onClick={() => buyAction()}>
            {buyBtnTxt || '구매하기'}
          </button>
        </div>
      </div>
      <InstallAlert ignoreRead={isWeb} setIgnore={setIsWeb} />
      <OrderSoldOutModal />
      <AuthModal
        txt={
          <>
            미성년자는 구매가 불가능한 <br /> 상품입니다.
          </>
        }
        isOpen={isOpenAuthModal}
        setIsOpen={setIsOpenAuthModal}
        btnType={'error'}
        action={() => setIsOpenAuthModal(false)}
      />
    </div>
  );
}
